// auth.scss

@import 'variables';

header {
    position: fixed;
    width: 100%;
    height: 59px;
    top: 0;
    background-color: $blue;
    z-index: 5;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
    
    img {
        display: block;
        height: 35px;
        margin: 10px auto 0;
    }
}

main, main > div, .img-cont {
    min-height: calc(100vh - 53px);
}

main {
    border-bottom: 1px solid #e4e4e4;
    
    & > div {
        width: 50%;
        float: right;
    }
}

.visual-half {
    background-color: #142e41;
    position: relative;
    padding-top: 59px;

    .box-container {
        width: calc(100% - 60px);
        max-width: 635px;
        margin-top: 30px;
        color: #fff;
        text-align: center;
    }

    h3 {
        font-size: 43px;
        margin-bottom: 18px;
        text-shadow: -1px 0 1px #fff, 1px 0 1px #fff, 0 -1px 1px #fff, 0 1px 1px #fff;
        letter-spacing: 0.055rem;
    }

    h4 {
        font-size: 26px;
        color: #e3c700;

        i {
            display: block;
            text-shadow: none;
        }
    }

    p {
        font-size: 20px;
        color: $lightgrey;
        margin-bottom: 20px;
    }

    .box {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 15px;
        font-size: 18px;
    }
}

.img-cont {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url("/img/happy-influencers.jpg") no-repeat top left / cover;
    opacity: 0.1;
    filter: blur(2px);
}

.middle-box {
    width: 85%;
    max-width: 450px;
    margin: 0 auto 20px;
    padding-top: 155px;
}

h1 {
    font-size: 31px;
    line-height: 1.5;
}

h2 {
    font-size: 18px;
    margin-bottom: 1.9rem;
    line-height: 1.5;
}

.form-control {
    border-radius: 0;
}

.form-floating input:focus {
    box-shadow: none;
}

input[name="pmvfzg"] {
    display: none;
}

.btn-primary {
    width: 100%;
    font-size: 19px;
    padding: 13px;
}

footer {
    font-size: 13px;
}

@media (max-width: 768px) {
    main, main > div {
        min-height: calc(100vh - 53px);
    }

    main > div {
        width: 100%;
        float: none;
    }
    
    .middle-box {
        padding-top: 95px;
    }
}

@media (min-width: 1125px) {
    .visual-half {
        h4 {
            font-size: 43px;
            text-shadow: -1px 0 1px #e3c700, 1px 0 1px #e3c700, 0 -1px 1px #e3c700, 0 1px 1px #e3c700;
        }

        p {
            font-size: 26px;
            margin-bottom: 60px;
        }
        
        .box {
            width: calc(50% - 50px);
            max-width: 260px;
            margin-bottom: 50px;
            font-size: 24px;

            &:nth-child(2n + 1) {
                margin-right: 50px;
            }

            &.box-pad-more {
                padding-top: 34px;
            }
        }
    }

    .box-row {
        display: flex;
        align-items: stretch;
    }
}
